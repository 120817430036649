import Vue from "vue";
import Backoffice from "@snark/backoffice";

import crudBouser from "./crud/bouser";
import crudUser from "./crud/user";
import crudNotification from "./crud/notification";
import crudContact from "./crud/contact";
import crudPartner from "./crud/partner";
import crudGift from "./crud/gift";
import crudLegals from "./crud/legals";
import crudParticipation from "./crud/participation";

import moment from "moment-timezone";
import axios from "axios";
moment.tz.setDefault('Europe/Paris');
moment.locale('fr');

Vue.config.productionTip = false;

Vue.use(Backoffice, {
    title: "oneforyougolf - BO",
    debug: true,
    apiUrl: process.env.VUE_APP_API_URL,
    apiVersion: 2,
    gmapApiKey: process.env.VUE_APP_GMAP_KEY,
    logAs: {
        token: process.env.VUE_APP_LOGAS_TOKEN,
        frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL,
        apiRoute: process.env.VUE_APP_LOGAS_API_ROUTE
    },
    authenticate: async function (login, password) {
        try {
            const result = await axios.post(process.env.VUE_APP_API_AUTHENTICATE_URL, {
                username: login,
                password: password
            });
            if (result.data.token) {
                return {token: result.data.token};
            }
        }
        catch(err) {
            console.log(err);
        }
        throw "unauthorized";
    },
    crud: async function () {
        // app
        Vue.crud(crudUser);
        Vue.crud(crudContact);
        Vue.crud(crudParticipation);

        // data
        Vue.crud(crudPartner);
        Vue.crud(crudGift);
        Vue.crud(crudLegals);

        // finance

        // backoffice
        Vue.crud(crudBouser);

        // operation
        Vue.crud(crudNotification);
    },
    defaultCRUDOptions: {
        editor: {
            select: {
                sort: 'asc'
            },
            checks: {
                sort: 'asc'
            }
        }
    },
    //routes: {
    //    prepare: function (routes) {
    //    }
    //},
    menuSections: ["app", "data", "finance", "backoffice", "operation"],
    dashboardBlocks: []
});

Vue.backoffice.start(Vue);
