import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
	user: Backoffice.populator("user", {
		displayProperty: item => {return item.firstname + ' ' + item.lastname;},
	}),
};

export default {
	name: "participation",
	collection: "participation",
	idProperty: "_id",
	section: "app",
	sort: {"back":[{"field":"createdAt","order":"desc"}]},
	backPopulateList: ["user"],
	labels: {"singleTitle":"Participation","listTitle":"Participations"},
	emptyItem: {"selfie":null},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "selfie",
			label: "Selfie",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxHeight: 400,})),
			noChildren: true,
		},
		{
			name: "userId",
			label: "User Id",
			formatter: Backoffice.formatters.linkObjectId("user", {idProperty: "_id","label": context.user.formatter()}),
		},
		{
			name: "result",
			label: "Result",
			formatter: Backoffice.formatters.mapping({"fail": "Fail", "success": "Success"}),
			editor: {type: "select", options: [{value: "fail", label: "Fail"}, {value: "success", label: "Success"}]},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "selfie",
			label: "Selfie",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxWidth: 150,})),
			noChildren: true,
		},
		{
			name: "user",
			label: "User",
			formatter: Backoffice.formatters.fullName(),
			nosingle: true,
		},
		{
			name: "result",
			label: "Result",
			formatter: Backoffice.formatters.mapping({"fail": "Fail", "success": "Success"}),
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	loadSingle() {
		return Promise.all([
			context.user.load(),
		]);
	},
};