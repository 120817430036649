import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
};

export default {
	name: "partner",
	collection: "partner",
	idProperty: "_id",
	section: "data",
	labels: {"singleTitle":"Partner","listTitle":"Partners"},
	emptyItem: {"logo":null},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "logo",
			label: "Logo",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxWidth: 150,})),
			editor: {
				type: "image",
				accept: "image/png,image/jpeg",
				preview: {
					urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",
					contain: true,
					formatUrl: Backoffice.formatters.subProperty('path'),
				}
			},
			noChildren: true,
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "slogan",
			label: "Slogan",
		},
		{
			name: "description",
			label: "Description",
			formatter: Backoffice.formatters.multiLines(),
			editor: {"type":"text","lines":5},
		},
		{
			name: "color",
			label: "Color",
		},
		{
			name: "active",
			label: "Active",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
			editor: {type: "boolean", labels: ["Non", "Oui"]},
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "logo",
			label: "Logo",
			formatter: Backoffice.formatters.subProperty('path', Backoffice.formatters.picture({urlPrefix: process.env.VUE_APP_API_STATIC_URL + "/",maxWidth: 150,})),
			noChildren: true,
		},
		{
			name: "name",
			label: "Name",
		},
		{
			name: "slogan",
			label: "Slogan",
		},
		{
			name: "active",
			label: "Active",
			formatter: Backoffice.formatters.boolean(["Non", "Oui"]),
		},
	],
	loadSingle() {
		return Promise.all([
		]);
	},
};