import Backoffice from "@snark/backoffice"

// noinspection JSUnusedLocalSymbols
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
	function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
	return new (P || (P = Promise))(function (resolve, reject) {
		function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
		function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
		function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
		step((generator = generator.apply(thisArg, _arguments || [])).next());
	});
};

const context = {
};

export default {
	name: "legals",
	collection: "legals",
	idProperty: "_id",
	section: "data",
	labels: {"singleTitle":"Legals","listTitle":"Legals"},
	emptyItem: {"blocks":[]},
	fields: [
		{
			name: "_id",
			label: "Id",
			readOnly: true,
			hideOnCreate: true,
		},
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"termsAndConditions": "Terms And Conditions", "privacyPolicy": "Privacy Policy", "legalTerms": "Legal Terms", "cookies": "Cookies"}),
			editor: {type: "select", options: [{value: "termsAndConditions", label: "Terms And Conditions"}, {value: "privacyPolicy", label: "Privacy Policy"}, {value: "legalTerms", label: "Legal Terms"}, {value: "cookies", label: "Cookies"}]},
		},
		{
			name: "blocks",
			label: "Blocks",
			list: true,
			children: [
				{
					label: "Sections",
					formatter: Backoffice.formatters.richBlock(),
					editor: {"type":"custom","component":"richblock"},
				},
			],
		},
		{
			name: "createdAt",
			label: "Created At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
		{
			name: "updatedAt",
			label: "Updated At",
			readOnly: true,
			hideOnCreate: true,
			formatter: Backoffice.formatters.date("DD/MM/YYYY"),
		},
	],
	listColumns: [
		{
			name: "type",
			label: "Type",
			formatter: Backoffice.formatters.mapping({"termsAndConditions": "Terms And Conditions", "privacyPolicy": "Privacy Policy", "legalTerms": "Legal Terms", "cookies": "Cookies"}),
		},
	],
	loadSingle() {
		return Promise.all([
		]);
	},
};